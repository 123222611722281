import React from 'react';
import { StyledLogin } from './StyledLoginDiv';

export const PageExpired = ({ manifest }) => {
  return (
    <StyledLogin
      component={"div"}
      rememberBox={false}
      manifest={manifest}
      headerText={window.pageExpiredTitle}
      subheaderTextInnerHtml={window.pageExpiredBody}
      buttonLabel={window.backToLogin}
      onClick={() => {
        window.location.replace(window.loginContinueLink);
      }}
    />
  );
};
