export const globalStyles = {
  divStyle: {
    position: 'absolute',
    left: ' 50%',
    top: '50%',
    width: '450px',
    transform: 'translate(-50%, -50%)',
  },
  backgroundImage: props => ({
    background: `url(${props.bgImage})`,
    position: 'absolute',
    backgroundSize: 'cover',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '-1',
  }),
  opacity: {
    opacity: 0.25,
  },
};

export const esqueciSenhaStyles = {
  reSend: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: 'blue',
    },
  },
  button: {
    height: 41,
  },
  input: {
    padding: 0,
    margin: 0,
  },
  verificationCodeContainer: {
    display: 'flex',
  },
  containerInfoSend: {
    backgroundColor: '#ff9d0d',
    borderRadius: 4,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
};
