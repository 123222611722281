import { Button, Input } from '@tecsinapse/ui-kit';
import React, { useRef, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { esqueciSenhaStyles } from './styles';
import { StyledLogin } from './StyledLoginDiv';
import { ReCAPTCHA } from 'react-google-recaptcha';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { getValueFromQueryParams } from "./utils/helpers";

export const CustomResetSenha = ({ manifest }) => {
  const classes = makeStyles(esqueciSenhaStyles)();

  /**
   * Constante "REQUEST_TIME_IN_SECONDS" define o tempo para reenvio de código
   * */
  const REQUEST_TIME_IN_SECONDS = window.countdown || 30;

  const [email, setEmail] = useState(getValueFromQueryParams('defaultEmail'));
  const [verificationCode, setVerificationCode] = useState('');
  const [requestTime, setRequestTime] = useState(REQUEST_TIME_IN_SECONDS);
  const [showEmailInput, setShowEmailInput] = useState(true);
  const [activeRequest, setActiveRequest] = useState(false);

  const grecaptchaObject = window.grecaptcha;

  const intervalID = useRef();

  const sendVerificationCode = async () => {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('kind', window.verificationCodeKind);
    try {
      await fetch(window.urlSendCode, {
        method: 'POST',
        body: params,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleSendCodeAndShowVerificationScreen = async () => {
    try {
      await sendVerificationCode();
    } catch (e) {
      return;
    }
    showVerificationCodeScreenAndRequestInterval();
  };

  const clearRequestInterval = () => {
    setActiveRequest(false);
    clearInterval(intervalID.current);
    setRequestTime(REQUEST_TIME_IN_SECONDS);
  };

  const showVerificationCodeScreenAndRequestInterval = () => {
    setShowEmailInput(false);
    setActiveRequest(true);
    let time = requestTime;

    intervalID.current = setInterval(() => {
      setRequestTime(time--);
      if (time < 0) clearRequestInterval();
    }, 999);
  };

  const countdowBtnLabel = activeRequest ? `(${("0" + requestTime).slice(-2)})` : window.labelButtonSend ?? 'Enviar Código';

  const mainDiv = { width: '100%', alignItems: 'center' };
  const marginTop10 = {
    marginTop: 10,
  };
  const hintDiv = {
    display: showEmailInput ? 'none' : 'block',
    marginTop: 5,
  };
  const hideElement = { display: 'none' };

  return (
    <StyledLogin
      manifest={manifest}
      headerText={window.forgotPasswordTitle}
      rememberBox={false}
      showButton={false}
      forgotPassword={{
        label: window.backToLogin,
        component: 'a',
        props: {
          href: window.loginUrl,
        },
      }}
    >
      <div style={mainDiv}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={1}
          direction="row"
        >
          <Grid item xs={12} style={marginTop10}>
            <Input
              className={classes.input}
              fullWidth
              value={email}
              onChange={event => setEmail(event.target.value)}
              type="text"
              name="username"
              id="username"
              startAdornment={<AccountCircle />}
              label={window.usernameOrEmail ?? 'E-mail'}
              autoComplete="off"
            />
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="flex-start"
            alignItems="flex-start"
            style={hintDiv}
          >
            <div className={classes.containerInfoSend}>
              <Icon path={mdiInformation} size={0.8} color="white" />
              <Typography
                variant="body2"
                style={{ marginLeft: 5, color: 'white' }}
              >
                {window.pageHint ??
                  'Você recebeu um código em seu e-mail, insira abaixo:'}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
            className={classes.verificationCodeContainer}
          >
            <Input
              className={classes.input}
              value={verificationCode}
              onChange={event => {
                setVerificationCode(event.target.value);
              }}
              maxLength={6}
              name="code"
              id="code"
              label={
                window.labelInputCodeVerification ?? 'Código de Verificação'
              }
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
            className={classes.verificationCodeContainer}
          >
            <Button
              className={classes.button}
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSendCodeAndShowVerificationScreen}
              disabled={!email || activeRequest}
            >
              {`${countdowBtnLabel}`}
            </Button>
          </Grid>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Input
              type="hidden"
              name="current.flow.recaptcha"
              style={hideElement}
            />
            <ReCAPTCHA
              sitekey={window.recaptchaSitekey}
              theme={window.recaptchaTheme}
              grecaptcha={grecaptchaObject}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              fullWidth
              variant="contained"
              color="primary"
              disabled={verificationCode.length !== 6}
            >
              {window.doSubmit ?? 'Confirmar Código'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </StyledLogin>
  );
};
