import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Login } from '@tecsinapse/login';
import { globalStyles } from './styles';

const useStyles = makeStyles(globalStyles);

export const StyledLogin = ({
  manifest,
  children,
  component: Component = 'form',
  ...props
}) => {
  const { icons, login_background_images: bgImages, isMobile } = manifest;
  const bgImage = bgImages.slice(-1)[0];
  const classes = useStyles({ bgImage: bgImage.src });

  return (
    <Component id="kc-form-login" method="post" action={window.loginAction}>
      <div
        className={[
          classes.backgroundImage,
          isMobile ? classes.opacity : '',
        ].join(' ')}
      />
      <div className={isMobile ? undefined : classes.divStyle}>
        <Login
          headerImages={[icons.slice(-1)[0].src]}
          variant="auto"
          {...props}
        >
          {children}
        </Login>
      </div>
    </Component>
  );
};
