import { IconButton } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Input } from '@tecsinapse/ui-kit';
import React, { useState } from 'react';
import { StyledLogin } from './StyledLoginDiv';

export const UpdatePassword = ({ manifest }) => {
  const [passwordFieldTypeNew, setPasswordFieldTypeNew] = useState('password');
  const togglePasswordFieldTypeNew = () => {
    setPasswordFieldTypeNew(type => {
      if (type === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };
  const [passwordFieldTypeConfirm, setPasswordFieldTypeConfirm] = useState(
    'password'
  );
  const togglePasswordFieldTypeConfirm = () => {
    setPasswordFieldTypeConfirm(type => {
      if (type === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    });
  };
  return (
    <StyledLogin
      manifest={manifest}
      headerText={window.updatePasswordTitle}
      buttonLabel={window.doSubmit}
      rememberBox={false}
      forgotPassword={{
        label: window.backToLogin,
        component: 'a',
        props: {
          href: window.loginUrl,
        },
      }}
    >
      <Input
        id="password-new"
        name="password-new"
        type={passwordFieldTypeNew}
        label={window.passwordNew}
        fullWidth
        startAdornment={<Lock />}
        endAdornment={
          <IconButton onClick={togglePasswordFieldTypeNew}>
            {passwordFieldTypeNew === 'text' ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}
          </IconButton>
        }
        endAdornmentMargin={false}
      />

      <Input
        id="password-confirm"
        name="password-confirm"
        type={passwordFieldTypeConfirm}
        label={window.passwordConfirm}
        fullWidth
        startAdornment={<Lock />}
        endAdornment={
          <IconButton onClick={togglePasswordFieldTypeConfirm}>
            {passwordFieldTypeConfirm === 'text' ? (
              <Visibility />
            ) : (
              <VisibilityOff />
            )}
          </IconButton>
        }
        endAdornmentMargin={false}
      />
    </StyledLogin>
  );
};
