import React from 'react';
import {StyledLogin} from './StyledLoginDiv';

export const Terms = ({manifest}) => {
    return (
        <StyledLogin
            manifest={manifest}
            headerText={window.termsTitle}
            subheaderTextInnerHtml={window.termsText}
            buttonLabel={window.doAccept}
            forgotPassword={{
                label: window.backToLogin,
                component: 'a',
                props: {
                    href: window.loginUrl,
                },
            }}
        />
    );
};
