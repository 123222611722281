import { ThemeProvider } from '@tecsinapse/ui-kit';
import React from 'react';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function AppContent(props) {
  const { Component } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const manifest = {
    isMobile,
    icons: [
      {
        src: `${window.resourcesFolder}/images/common/logo-portal.png`,
      },
    ],
    login_background_images: [
      {
        src: `${window.resourcesFolder}/images/background/bg${
          isMobile ? '-mobile-' : '-'
        }${random(
          0,
          parseInt(
            isMobile ? window.mobilebgLastNumber : window.bgLastNumber,
            10
          )
        )}.jpg`,
      },
    ],
  };
  return (
    <div className="App">
      <Component manifest={manifest} isMobile={isMobile} />
    </div>
  );
}

export default props => (
  <ThemeProvider variant={window.uiKitTheme}>
    <AppContent {...props} />
  </ThemeProvider>
);
