import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Snackbar } from './Snackbar';
import { EsqueciSenha } from './EsqueciSenha';
import { UpdatePassword } from './UpdatePassword';
import { Login } from './Login';
import { Recaptcha } from './Recaptcha';
import { WeAreSorry } from './WeAreSorry';
import { InfoMessage } from './InfoMessage';
import { PageExpired } from './PageExpired';
import { Terms } from './Terms';
import { CustomResetSenha } from './CustomResetSenha';

function renderLogin() {
  ReactDOM.render(
    <App Component={Login} />,
    document.getElementById('kc-login-react')
  );
}

window.renderLogin = renderLogin;

function renderForgotPassword() {
  ReactDOM.render(
    <App Component={EsqueciSenha} />,
    document.getElementById('kc-login-react')
  );
}

window.renderForgotPassword = renderForgotPassword;

function renderCustomResetPassword() {
  ReactDOM.render(
    <App Component={CustomResetSenha} />,
    document.getElementById('kc-custom-reset-senha-react')
  );
}

window.renderCustomResetPassword = renderCustomResetPassword;

function renderUpdatePassword() {
  ReactDOM.render(
    <App Component={UpdatePassword} />,
    document.getElementById('kc-update-password-react')
  );
}

window.renderUpdatePassword = renderUpdatePassword;

function renderRecaptcha() {
  ReactDOM.render(
    <App Component={Recaptcha} />,
    document.getElementById('kc-recaptcha')
  );
}
window.renderRecaptcha = renderRecaptcha;

function renderWeAreSorry() {
  ReactDOM.render(
    <App Component={WeAreSorry} />,
    document.getElementById('kc-error')
  );
}

window.renderWeAreSorry = renderWeAreSorry;

function renderInfoMessage() {
  ReactDOM.render(
    <App Component={InfoMessage} />,
    document.getElementById('kc-info-message')
  );
}

window.renderInfoMessage = renderInfoMessage;

function renderPageExpired() {
  ReactDOM.render(
    <App Component={PageExpired} />,
    document.getElementById('kc-page-expired-react')
  );
}

window.renderPageExpired = renderPageExpired;

function renderShowSnackbar(message, variant) {
  ReactDOM.render(
    <Snackbar message={message} variant={variant} />,
    document.getElementById('kc-message')
  );
}

window.renderShowSnackbar = renderShowSnackbar;

function renderTerms() {
  ReactDOM.render(
    <App Component={Terms} />,
    document.getElementById('kc-terms')
  );
}

window.renderTerms = renderTerms;

if (process.env.REACT_APP_HOST === 'development') {
  window.loginTitle = 'Portal Abraland';

  window.uiKitTheme = 'green';
  window.bgLastNumber = 9;
  window.mobilebgLastNumber = 3;
  window.kcBaseUrl = 'https://portalabraland.com.br';
  window.realmThemeName = 'abraland';
  window.resourcesFolder =
    'https://cdn.portaltecsinapse.com.br/src/keycloak/theme/abraland';
  window.backToLogin = '« Voltar';
  window.forgotPasswordTitle = 'Esqueceu a senha?';
  window.recaptchaSitekey = '6Lc8ZgoUAAAAAC4I4BYxLuZFLdCuI6T3xSKCdTAt';
  window.recaptchaTheme = 'light';
  window.loginUrl = '#';
  renderCustomResetPassword();
}
