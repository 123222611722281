import React from 'react';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { Input } from '@tecsinapse/ui-kit';
import { StyledLogin } from './StyledLoginDiv';

export const Recaptcha = ({ manifest }) => {
  const grecaptchaObject = window.grecaptcha;
  return (
    <StyledLogin
      rememberBox={false}
      manifest={manifest}
      headerText={window.captchaTitle}
      subheaderText={window.captchaDetails}
      buttonLabel={window.continueMessage}
    >
      <Input
        type="hidden"
        name="current.flow.recaptcha"
        style={{ display: 'none' }}
      />
      <ReCAPTCHA
        sitekey={window.recaptchaSitekey}
        theme={window.recaptchaTheme}
        grecaptcha={grecaptchaObject}
      />
    </StyledLogin>
  );
};
