import { IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Input } from '@tecsinapse/ui-kit';
import React, { useState } from 'react';
import { DefaultProductTypography } from '@tecsinapse/ui-kit/build/components/Menu/Drawer/DefaultProductTypography/DefaultProductTypography';
import { StyledLogin } from './StyledLoginDiv';
import Grid from '@material-ui/core/Grid';
import {getValueFromQueryParams} from "./utils/helpers";

const styles = { button: { padding: 8 } };

export const Login = ({ manifest }) => {
  const [passwordFieldType, setPasswordFieldType] = useState('password');
  const togglePasswordFieldType = () => {
    setPasswordFieldType(type => {
      if (type === 'password') {
        return 'text';
      }
      return 'password';
    });
  };
  const [title, subtitle] = window.loginTitle.split(' ');

  return (
    <StyledLogin
      rememberBox
      manifest={manifest}
      headerText={
        <DefaultProductTypography title={title} subtitle={subtitle} />
      }
      rememberLabel={window.rememberMe}
      googleProvider={{
        label: window.googleLoginButtonLabel,
        loginUrl: window.googleLoginButtonURL,
      }}
      forgotPassword={{
        label: window.doForgotPassword,
        component: 'a',
        props: {
          href: window.forgotPasswordUrl,
        },
      }}
      buttonLabel={window.doLogin}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12} md={12}>
          <Input
            type="text"
            id="username"
            name="username"
            label={window.email}
            fullWidth
            startAdornment={<AccountCircle />}
            margin="none"
            defaultValue={getValueFromQueryParams('defaultEmail')}
          />
        </Grid>
        <Grid item xs={12} xl={12} md={12}>
          <Input
            id="password"
            name="password"
            label={window.password}
            type={passwordFieldType}
            fullWidth
            startAdornment={<Lock />}
            margin="none"
            endAdornment={
              <IconButton
                onClick={togglePasswordFieldType}
                style={styles.button}
              >
                {passwordFieldType === 'text' ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            }
            endAdornmentMargin={false}
          />
        </Grid>
      </Grid>
    </StyledLogin>
  );
};
