import React, { useState } from 'react';
import { Snackbar as UIKitSnackbar } from '@tecsinapse/ui-kit';
import { ThemeProvider } from '@tecsinapse/ui-kit';

export const Snackbar = ({ message, variant }) => {
  const [show, setShow] = useState(true);
  return (
    <ThemeProvider variant="orange">
      <UIKitSnackbar
        show={show}
        onClose={() => setShow(false)}
        variant={variant === 'info' ? 'warning' : variant}
      >
        {message}
      </UIKitSnackbar>
    </ThemeProvider>
  );
};
