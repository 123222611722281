import AccountCircle from '@material-ui/icons/AccountCircle';
import { Input } from '@tecsinapse/ui-kit';
import React from 'react';
import { StyledLogin } from './StyledLoginDiv';
import { getValueFromQueryParams } from "./utils/helpers";

export const EsqueciSenha = ({ manifest }) => {
  return (
    <StyledLogin
      manifest={manifest}
      headerText={window.forgotPasswordTitle}
      buttonLabel={window.doSubmit}
      rememberBox={false}
      forgotPassword={{
        label: window.backToLogin,
        component: 'a',
        props: {
          href: window.loginUrl,
        },
      }}
    >
      <Input
        type="text"
        name="username"
        defaultValue={getValueFromQueryParams('defaultEmail')}
        label={window.email}
        fullWidth
        startAdornment={<AccountCircle />}
      />
    </StyledLogin>
  );
};
