import React from 'react';
import { StyledLogin } from './StyledLoginDiv';

export const WeAreSorry = ({ manifest }) => {
  return (
    <StyledLogin
      component={"div"}
      rememberBox={false}
      manifest={manifest}
      headerText={window.weAreSorryTitle}
      subheaderText={window.weAreSorrySubtitle}
      buttonLabel={window.weAreSorryButtonLabel}
      onClick={() => {
        window.location.replace(window.baseUrl);
      }}
    />
  );
};
